import React, { useEffect, useState } from "react"
import { Card, Grid } from "@material-ui/core"
import { NavbarLayout } from "../layouts/AppLayout"
import AuthorizedPageWrapper from "../context/AuthorizedPageWrapper"
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core"
import { ACCOUNT_ADMIN_NAME } from "../constants/products"
import MembersTool from "../components/MembersTool"
import KnowledgeAdminTool from "../components/KnowledgeAdminTool"
import {
  FaBook,
  FaBrain,
  FaPersonBooth,
  FaRocket,
  FaUser,
} from "react-icons/fa6"
import MindManager from "../components/MindManager"
import AgentDeveloper from "../components/AgentDeveloper"
import WebAgentHistory from "../components/WebAgentHistory"
import AgentStatsTool from "../components/AgentStatsTool"

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    width: "100%",
    minHeight: "100vh",
    zIndex: theme.zIndex.drawer + 1,
  },
  fabPaper: {
    position: "fixed",
    right: 90,
    bottom: 20,
    padding: 10,
    borderRadius: 50,
    backgroundColor: "#f5f5f5",
    [theme.breakpoints.down("md")]: {
      bottom: "3vh",
    },
  },
  fab: {
    position: "fixed",
    right: 20,
    bottom: 20,
    [theme.breakpoints.down("md")]: {
      bottom: "3vh",
    },
  },
  fabIcon: {
    color: "#fff",
  },
  agentCard: {
    position: "fixed", // Changed position to 'fixed'
    right: 50,
    bottom: 90,
    width: 400,
    height: 700,
    backgroundColor: "white",
    zIndex: 1000,
    borderRadius: "16px",
    [theme.breakpoints.down("md")]: {
      right: 0,
      left: 0,
      top: 0,
      width: "100vw",
      height: "100vh",
      borderRadius: 0,
    },
  },
  drawer: {
    flexShrink: 0,
    height: "100vh",
  },
  drawerPaper: {
    width: 240,
    marginTop: 90, // Ajustar según la altura de tu Navbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),

    marginLeft: 240, // Ajustar según el ancho de tu AdminPanel
  },
}))

const ArticleBender = () => {
  const [openChat, setOpenChat] = useState(false)

  return (
    <NavbarLayout
      setOpenChat={setOpenChat}
      openChat={openChat}
      product={ACCOUNT_ADMIN_NAME}
      disableFooter={true}
    >
      <AuthorizedPageWrapper>
        <App />
      </AuthorizedPageWrapper>
    </NavbarLayout>
  )
}

const AdminPanel = ({ tools, setSelectedTool, selectedTool }) => {
  const classes = useStyles()

  return (
    <Card
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      <List>
        {/* Aquí se pueden agregar más elementos según sea necesario */}

        {tools.map((tool) => {
          const Icon = tool.icon
          const name = tool.name
          const isSelected = tool.id === selectedTool
          return (
            <ListItem
              button
              selected={isSelected}
              onClick={() => setSelectedTool(tool.id)}
            >
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={name} />
            </ListItem>
          )
        })}
      </List>
    </Card>
  )
}

const App = () => {
  const [selectedTool, setSelectedTool] = useState("members")
  const query = new URLSearchParams(location.search)
  const toolFromQuery = query.get("tool")
  const tools = [
    {
      id: "members",
      name: "Miembros",
      icon: FaUser,
      content: MembersTool,
    },
    {
      id: "knowledgeAdmin",
      name: "Gestor de Documentos",
      icon: FaBook,
      content: () => <KnowledgeAdminTool />,
    },
    {
      id: "mindAdmin",
      name: "Prompt de Agente",
      icon: FaBrain,
      content: () => <MindManager />,
    },
  ]

  useEffect(() => {
    if (toolFromQuery && tools.some((tool) => tool.id === toolFromQuery)) {
      setSelectedTool(toolFromQuery)
    }
  }, [toolFromQuery, tools])

  const handleSelectTool = (tool) => {
    setSelectedTool(tool)
    // set query param tool also
    const url = new URL(location.href)
    url.searchParams.set("tool", tool)
    history.pushState({}, "", url)
  }

  const ActiveComponent = tools.find((tool) => tool.id === selectedTool).content

  return (
    <Grid container justify="center">
      <Grid item md={2}>
        <AdminPanel
          tools={tools}
          selectedTool={selectedTool}
          setSelectedTool={handleSelectTool}
        />
      </Grid>
      <Grid item md={10} container>
        <ActiveComponent />
      </Grid>
    </Grid>
  )
}

export default ArticleBender
